body {
  margin: 0;
  font-family: 'BasierRegular', apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Label overrider style */
.ant-form-item-required::before {
  display: none !important;
  margin-bottom: 8px !important;
}
.ant-form-item-explain-error {
  font-size: 13px !important;
  line-height: 16.38px;
}
.ant-form-item-label {
  padding: 0 0 2px !important;
}
